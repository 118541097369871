import React from 'react';
import content from '../resumeContent.js';

const { education } = content;

const VertSeparator = () => <div className="mx-2">|</div>;

const SectionHeader = ({ title }) => (
  <>
    <header className="text-lg font-bold">{title}</header>
    <div className="bg-black w-full mb-1" style={{ height: '1px' }} />
  </>
);

const List = ({ items }) => (
  <ul className="list-disc pl-12">
    {items.map(item => (
      <li
        key={item}
        className="m-0"
        dangerouslySetInnerHTML={{ __html: item }}
      ></li>
    ))}
  </ul>
);

const HighlightsHeader = ({ title, date, subtitle, location }) => (
  <section className="flex justify-between mb-1">
    <div className="flex flex-col">
      <strong>{title}</strong>
      <span>{subtitle}</span>
    </div>
    <div className="flex flex-col">
      <span>{date}</span>
      <em className="text-right">{location}</em>
    </div>
  </section>
);

const Resume = () => (
  <div className="mx-auto p-6 text-xs" style={{ maxWidth: '1100px' }}>
    <h1 className="text-center mb-3">{content.name}</h1>
    <section className="flex justify-center mb-3">
      <div>
        <a href={`https://${content.links.personal}`}>
          {content.links.personal}
        </a>
      </div>
      <VertSeparator />
      <div>{content.phone}</div>
      <VertSeparator />
      <div>
        <a href={`mailto:${content.email}`}>{content.email}</a>
      </div>
    </section>

    <section className="mb-2">
      <SectionHeader title="Education" />
      <HighlightsHeader
        title={education.university}
        subtitle={education.degree}
        date={education.dates}
        location={`GPA: ${education.gpa}`}
      />
    </section>

    <section className="mb-2">
      <SectionHeader title="Experience" />
      {content.experience.map(
        ({ dates, highlights, location, organization, title }) => (
          <div className="mb-2" key={organization}>
            <HighlightsHeader
              title={organization}
              date={dates}
              subtitle={title}
              location={location}
            />
            <List items={highlights} />
          </div>
        )
      )}
    </section>
    <section>
      <SectionHeader title="Accomplishments" />
      <List items={content.accomplishments} />
    </section>
  </div>
);

export default Resume;
