export default {
  name: 'Brett Fisher',
  links: {
    linkedin: 'https://www.linkedin.com/in/brett-fisher-82580416b/',
    personal: 'brettfisher.dev',
  },
  phone: '(970) 402-8442',
  email: 'brett.fisher988@gmail.com',
  experience: [
    {
      organization: 'Amazon',
      location: 'Seattle, WA',
      title: 'Software Engineering Intern',
      dates: 'June 2020 - August 2020',
      highlights: [
        "Built internal site used by associates at Amazon's hundreds of physical stores across the world (<b>React</b>)",
        'Created compatibility layer for new React project, used by multiple teams in dozens of different dashboard projects (<b>AngularJS</b>, <b>Webpack</b>, <b>Gulp</b>)',
        "Collaborated with UX designers to suggest and implement improvements to the internal site's user experience",
        'Tested internal site with <b>Karma</b>, <b>Jest</b>, and <b>React Testing Library</b>',
      ],
    },
    {
      organization: 'Bluehost',
      location: 'Provo, UT',
      title: 'Software Engineering Intern',
      dates: 'January 2020 - May 2020',
      highlights: [
        'Created landing page seen by all new Bluehost customers (<b>React</b>, <b>Sass</b>)',
        "Implemented changes to user experience for managing DNS records on customers' sites",
        'Worked with UX designers to identify and fix dashboard responsiveness problems',
        'Unit tested React components with <b>Jest</b>, <b>React Testing Library</b>, and <b>Enzyme</b>',
      ],
    },
    {
      organization: 'Brigham Young University',
      location: 'Provo, UT',
      title: 'Full-stack Web Developer',
      dates: 'September 2019 - May 2020',
      highlights: [
        'Created a website from scratch to facilitate ancient Assyrian research (<a href="http://oare.byu.edu">oare.byu.edu</a>)',
        'Created a <b>Vue</b> frontend and <b>TypeScript</b> + <b>Express</b> backend interacting with a <b>MySQL</b> database',
        'Containerized with <b>Docker</b> and created <b>CI/CD</b> pipeline to deploy site to <b>AWS</b>',
        'Interviewed candidates for additional developer role and functioned as tech lead to manage a second developer',
      ],
    },
    {
      organization: 'SimpleNexus, LLC',
      location: 'Lehi, UT',
      title: 'Web Development Intern',
      dates: 'June 2019 - August 2019',
      highlights: [
        'Created a web admin tool with <b>Vue</b> and <b>GraphQL</b> to simulate the company’s new mobile activity feed feature for use by customer support',
        'Wrote API endpoints with <b>Ruby on Rails</b> to display statistics about consumer usage of the mobile app',
        'Wrote unit tests using <b>Jest</b> and <b>Rspec</b> to ensure Vue components and Rails code functioned properly',
      ],
    },
    {
      organization: 'BYU Center for Teaching and Learning',
      location: 'Provo, UT',
      title: 'Front-end Web Developer',
      dates: 'September 2018 - April 2019',
      highlights: [
        'Migrated the frontend of BYU’s grade management website from <b>jQuery</b> to <b>Vue</b>',
        'Wrote unit tests for Vue components using <b>Mocha</b> and <b>Chai</b>',
        'Created an interactive style guide for custom Vue components to help developers begin using them in their code',
      ],
    },
  ],
  education: {
    university: 'Brigham Young University',
    degree: 'B.S. in Computer Science',
    dates: 'Expected Graduation: April 2021',
    gpa: '3.94',
  },
  accomplishments: [
    'Hacker Studio: Winner in the Devpost Hacklarious Hackathon (Best use of MongoDB Atlas)',
    'Creator of a website for flexibly searching information on Chinese characters (<a href="https://chinesedb.netlify.app">chinesedb.netlify.app</a>)',
    'Skills: Piano, Fluent in Filipino (Tagalog), Upper Beginner in Mandarin Chinese',
  ],
};
